.pattern {
    position: absolute;
    overflow: hidden;
    top: 0;
    bottom: 0;
    height: 100%;

    &--left {
        left: 0;
    }

    &--right {
        right: 0;

        &:before {
            left: 200px;
            right: -20vw;
        }

        &:after {
            left: 270px;
            right: -20vw;
        }
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
    }

    &:before {
        transform: skewX(30deg);

background: -moz-linear-gradient(left, rgba(229,229,229,1) 0%, rgba(250,250,250,0) 16%, rgba(255,255,255,0) 20%);
background: -webkit-linear-gradient(left, rgba(229,229,229,1) 0%,rgba(250,250,250,0) 16%,rgba(255,255,255,0) 20%);
background: linear-gradient(to right, rgba(229,229,229,1) 0%,rgba(250,250,250,0) 16%,rgba(255,255,255,0) 20%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#00ffffff',GradientType=1 );
    }

    &:after {
        transform: skewX(-20deg);
        opacity: 0.5;

background: -moz-linear-gradient(left, rgba(214,214,214,1) 0%, rgba(252,252,252,0) 28%, rgba(255,255,255,0) 30%);
background: -webkit-linear-gradient(left, rgba(214,214,214,1) 0%,rgba(252,252,252,0) 28%,rgba(255,255,255,0) 30%);
background: linear-gradient(to right, rgba(214,214,214,1) 0%,rgba(252,252,252,0) 28%,rgba(255,255,255,0) 30%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d6d6d6', endColorstr='#00ffffff',GradientType=1 );
    }
}