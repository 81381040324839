.info {
    display: inline-block;
    margin-right: 1em;
    font-size: 0.9em;

    @media all and (min-width: 768px) {
        display: block;
        margin-right: 0;
    }

    &__icon {
        &-left {
            margin: {
                left: 0;
                right: 0.5em;
            }
        }

        &-right {
            margin: {
                left: 0.5em;
                right: 0;
            }
        }
    }

    svg {
        fill: $colorGray;
    }

    &--white-icon {
        svg {
            fill: #fff;
        }
    }
}