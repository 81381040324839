
.social-media {
    padding-left: 0;

    svg {
        fill: #fff;
        transition: all 0.2s ease;
    }

    &__link {
        display: inline-block;
        padding: 0.5em;

        &:hover {
            svg {
                fill: #999;
            }
        }
    }

    &--grey {
        svg {
            fill: #4c4c4c;
        }

        .social-media__link:hover {
            svg {
                fill: #999;
            }
        }
    }

    &--black {
        svg {
            fill: #000;
        }

        .social-media__link:hover {
            svg {
                fill: $colorRed;
            }
        }
    }

    &__item {
        @extend %verticalMiddleInlineBlock;

        &:not(:last-child) {
            margin-right: 0.7em;
        }
    }
}
