
@each $width in $columnsWidthSet {
    .col-#{$width} {
        width: $width * 1%;

        @media all and (max-width: 768px) {
            width: 100%;
        }
    }
}

.col-bg {
    padding: 40px;

    @media (max-width: 767px) {
        margin-bottom: 2em;
    }
}

.col-left-border {
    padding: {
        top: 20px;
        bottom: 20px;
    }

    &--red {
        border-left: 2px solid $colorRed;
    }

    @media all and (max-width: 767px) {
        &[class*="col-"] {
            padding-left: 40px !important;
        }
    }
}