.skew-overlay {
    position: absolute;
    top: 0;
    left: -35vw;
    z-index: 2;

    // height: 100vh;
    height: 100%;
    min-height: 100vh;
    width: 100vw;

    background-color: rgba(#fff, 0.8);

    transform: skewX(-15deg);
    transition: left .5s;

    @media all and (min-width: 1024px) and (max-width: 1699px) {
        left: -25vw;
    }

    &--white {
        background-color: #fff;
    }

    &--middle {
        width: calc(50vw + 33vw);
    }

    &--reversed {
        transform: skewX(15deg);
        transform-origin: top right;

        &.skew-overlay--middle {
            @media all and (min-width: 768px) and (max-width: 1023px) {
                width: 75vw;
            }

            @media all and (min-width: 1024px) and (max-width: 1699px) {
                width: 66vw;
            }

            @media all and (min-width: 1700px) {
                width: 78vw;
            }
        }
    }

    &--triple {
        transform: skewX(15deg);
        transform-origin: center right;
        left: -50vw;

        background-color: rgba(#fff, 0.7);

        @media all and (min-width: 200px) and (max-width: 991px) {
            left: 0vw;
        }

        &:before {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            background-color: rgba(#fff, 0.6);

            transform: skewX(-35deg);
            transform-origin: 0 -10%;
        }

        &:after {
            content: ' ';
            position: absolute;
            top: 0;
            left: -25vw;
            width: 100%;
            height: 100%;

            background-color: rgba(#fff, 0.6);

            // transform: skewX(30deg);
            transform-origin: top right;
        }
    }

    .l-header--short & {
        @media all and (max-width: 767px) {
            display: none;
        }
    }
}
