/**
*
* Button
*
**/

.btn {
    @include inline-block();
    padding: 20px 0;
    font: {
        size: rem(20px);
        weight: 500;
    }
    letter-spacing: rem(1px);
    background-color: transparent;

    &--grow {
        position: relative;
        overflow: hidden;
        transition: all .5s;

        font: {
            size: rem(20px);
        }

        &:before {
            content: ' ';
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            height: 100%;
            overflow: hidden;
            transform: translateY(100%) translateY(-3px);
            transition: transform .35s .2s;
        }

        &:after {
            content: attr(data-content);
            position: absolute;
            left: 0;
            top: 19.5px;
            display: block;
            width: 100%;
            text-align: center;
            color: transparent;
            transform: translateY(100%);
            transition: color .35s 0s, transform .35s 0s;
        }

        &:hover {
            padding: 20px;

            &:before {
                transform: translateY(0);
            }

            &:after {
                color: white;
                transform: translateY(0%);
                transition: color .35s .15s, transform .35s .15s;
            }

            .btn__txt {
                color: transparent;
                transform: translateY(-110%);
            }
        }

        &-left {
            &:hover {
                margin-left: -20px;
            }
        }
    }

    &--large {
        font-size: rem(22px);
    }

    &--small {
        padding: 5px 0;

        font: {
            size: rem(18px);
        }

        &:after {
            top: 6.5px;
        }

        &:hover {
            padding: 5px 10px;

            &.btn--grow-left {
                margin-left: -10px;
            }
        }
    }

    @each $color, $color_code in $bgColors {
        &--#{$color} {

            &.btn--grow {
                &:not([class*="color-"]) {
                    color: $color_code;
                }

                &:before {
                    border: 3px solid $color_code;
                    background-color: $color_code;
                }
            }
        }
    }

    &--white {
        &:hover {
            &:after {
                color: $colorBlack;
            }
        }
    }

    &__txt {
        display: block;
        transition: color .35s .2s, transform .35s .2s;
    }
}
