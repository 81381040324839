.bg {
    @each $colorName, $color in $bgColors {
        &-#{$colorName} {
            background-color: $color;
        }
    }

    &-black,
    &-dark-grey-gradient {
        color: #f0f3f4;
    }

    &-red {
        color: #fff;
    }

    &-video {
        position: relative;
        overflow: hidden;

        [class*="container"] {
            position: relative;
            z-index: 1;
        }

        &__main {
            object-fit: cover;
            min-height: 100%;
            min-width: 100%;
            width: 100%;

            &--fixed {
                position: fixed;
                z-index: -6;
            }
        }
    }

    &-image {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        &--right {
            background-position: center right;
            background-size: auto 100%;
        }

        &--full-height {
            @media all and (min-width: 1280px) {
                height: 100vh;
            }
        }
    }

    &-skewed-gradient {
        display: block;
        content: '';

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;

        height: 500px;

        @include angle-edge(topleft, 13deg);

        /*
            content: '';
            position: absolute;
            top: -80px;

            width: 150vw;
            height: 500px;

            background: red;
            @include angle-edge(topleft, 3deg);


            @media all and (min-width: 1024px) {
                top: -500px;
            }*/
    }

    &-dark-grey-gradient {
        background: #2b303a;
        background: -moz-linear-gradient(left, #2b303a 36%, #465356 100%);
        background: -webkit-linear-gradient(left, #2b303a 36%,#465356 100%);
        background: linear-gradient(to right, #2b303a 36%,#465356 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2b303a', endColorstr='#465356',GradientType=1 );
    }

    &-silver-gradient-top-bottom {
        background: -moz-linear-gradient(top, rgba(237,237,237,1) 0%, rgba(255,255,255,0) 100%);
        background: -webkit-linear-gradient(top, rgba(237,237,237,1) 0%,rgba(255,255,255,0) 100%);
        background: linear-gradient(to bottom, rgba(237,237,237,1) 0%,rgba(255,255,255,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#00ffffff',GradientType=0 );
    }



    &-nolan {
        width: 100%;
        height: 100%;
        padding: 34px 61px;

        &__layer {
            display: block;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, #00add4, #3aa396);
        }
    }
}
