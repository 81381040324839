
.horizontal-bar {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    opacity: 0;
    background-color: #222;

    transition: opacity 0.4s;

    @media all and (max-width: 767px) {
        white-space: nowrap;
        overflow-x: auto;
         -webkit-overflow-scrolling: touch;
    }

    .l-navbar--white & {
        opacity: 1;
    }

    &__item,
    &__link {
        display: inline-block;
    }

    &__item {
        &:first-child {
            margin-left: -1.5em;
        }
    }

    &__link {
        padding: 1em 1.5em;
        color: #ededed;
        font-weight: 500;
        @include dynamicFontSize(13, 17, 480, 1600);

        transition: background-color 0.2s ease;

        &.is-active {
            background-color: $colorRed;
        }

        &:not(.is-active):hover {
            background-color: #000;
        }
    }
}
