/**
*
* SASS Variables
*
**/

/* Colors */
$colorWhite: #fff;
$colorBlack: #000;
$colorMain: #000;
$colorRed: #e21838;
$colorMint: #059e93;
$colorOrange: #ff8816;
$colorGray: #505050;
$colorDarkGrey: #1a1a1a;
$colorMediumGray: #999; // old: #4d4d4d;
$colorLightGrey: #ededed;
$colorTwitterBlue: #4f96f5;
$colorDarkBlue: #061b37;
$colorPurple: #5b2986;
$colorDarkPurple: #191559;
$colorYellow: #ffc000;

$colorGold: #B48A74;
$colorBlue: #3299ff;
$colorOrange: #f57d30;

$columnsWidthSet: (10, 20, 25, 30, 33, 40, 50, 60, 67, 70, 75, 80, 90);

$bgColors: (
	'white': $colorWhite,
    'main': $colorMain,
    'red': $colorRed,
    'gray': $colorGray,
    'orange': $colorOrange,
    'mint': $colorMint,
    'black': $colorBlack,
    'purple': $colorPurple,
    'dark-purple': $colorDarkPurple,
    'twitter-blue': $colorTwitterBlue,
    'yellow': $colorYellow,
    'light-grey': $colorLightGrey
);

$csColors: (
    'gold': $colorGold,
    'blue': $colorBlue,
    'orange': $colorOrange
);

$footerBorderColor: #333;
