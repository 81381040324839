
.burger {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 111;
    display: block;
    width: 30px;
    height: 22px;
    margin: 30px;
    border: 0;
    background-color: transparent;
    cursor: pointer;

    @media all and (min-width: 1024px) {
        top: 6px;
    }

    &__line {
        position: absolute;
        display: block;
        height: 4px;
        width: 50%;
        background: #fff;
        opacity: 1;
        transform: rotate(0deg);
        transition: .25s ease-in-out, background .25s .2s ease;

        @media all and (max-width: 768px) {
            background-color: $colorMain;
        }

        .l-navbar--white &,
        .burger--black & {
            background: #000;
        }

        &:nth-child(even) {
            left: 50%;
            border-radius: 0 10px 10px 0;
        }

        &:nth-child(odd) {
            left: 0;
            border-radius: 10px 0 0 10px;
        }

        &:nth-child(1),
        &:nth-child(2) {
            top: 0;
        }

        &:nth-child(3),
        &:nth-child(4) {
            top: 9px;
        }

        &:nth-child(5),
        &:nth-child(6) {
            top: 18px;
        }
    }

    &--open {
        .burger__line {
            background-color: #fff;
            transition: .25s ease-in-out, background .25s ease-in-out;

            &:nth-child(1),
            &:nth-child(6) {
                transform: rotate(45deg);
            }

            &:nth-child(2),
            &:nth-child(5) {
                transform: rotate(-45deg);
            }

            &:nth-child(1) {
                left: 5px;
                top: 7px;
            }

            &:nth-child(2) {
                left: calc(50% - 5px);
                top: 7px;
            }

            &:nth-child(3) {
                left: -50%;
                opacity: 0;
            }

            &:nth-child(4) {
                left: 100%;
                opacity: 0;
            }

            &:nth-child(5) {
                left: 5px;
                top: 12px;
            }

            &:nth-child(6) {
                left: calc(50% - 5px);
                top: 12px;
            }
        }
    }
}
