.items-on-line {
    width: 100%;

    @media all and (min-width: 568px) {
        display: flex;
    }

    @media all and (max-width: 768px) {
        margin-top: 2em;
    }

    @media all and (min-width: 769px) {
        width: 70%;
    }

    &--middle {
        position: absolute;
        @extend %verticalAlignment;

        @media all and (max-width: 768px) {
            position: static;
            transform: none;
            top: 0;
        }
    }

    &--right {
        right: 0;
    }

    &__item {
        flex: 1 0 0px;
    }

    &__item:not(:first-child) {
        @media all and (min-width: 568px) {
            margin-left: 20px;
        }
    }
}