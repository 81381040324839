
@keyframes glow-grow {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.line-list {
    display: block;

    &-wrapper {
        position: relative;
        padding-right: 28%;

        @media all and (min-width: 200px) and (max-width: 1024px) {
            padding-right: 0;
        }

        &.lineListAnim {
            .line-list__icon {
                transform: scale(1);
            }

            .line-list__block {
                @extend .animated;
                @extend .flipInX;
            }

            .line-list-image {
                animation: dash 3.35s linear alternate 1 forwards;

                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    -webkit-animation: none;
                    animation: none;
                }
            }
        }
    }

    &-ratio-image {
        @media all and (min-width: 200px) and (max-width: 1024px) {
            display: none;
        }
    }

    &-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 72%;
        height: 100%;

        stroke-dasharray: 5000;
        stroke-dashoffset: 5000;

        @supports (-ms-ime-align:auto) {
            stroke-dasharray: none;
            stroke-dashoffset: none;
        }

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            stroke-dasharray: none;
            stroke-dashoffset: none;
        }

        @media all and (min-width: 200px) and (max-width: 1024px) {
            display: none;
        }
    }

    &-main-content {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 40%;

        @media all and (min-width: 1201px) and (max-width: 1350px) {
            width: 45%;
        }

        @media all and (min-width: 200px) and (max-width: 1200px) {
            position: static;
            width: 100%;
            margin-bottom: 3rem;
        }

        > p {
            font: {
                size: rem(18px);
                weight: 300;
            }
            line-height: rem(20px);

            @media all and (min-width: 1201px) and (max-width: 1350px) {
                font-size: rem(16px);
            }

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

    &__item {
        position: absolute;
        display: flex;
        flex-flow: row nowrap;
        cursor: pointer;

        @media all and (min-width: 200px) and (max-width: 1024px) {
            position: static;
            margin-bottom: 2rem;
        }

        &:not(.is-active):hover {
            .line-list__headline {
                transform: scale(1.2);
            }
        }


        &--discover {
            top: 43%;
            left: -3%;

            .line-list__icon {
                @media all and (min-width: 1025px) {
                    transition: transform 0.3s cubic-bezier(.39,-.35,.21,1.7);
                }
            }

            .line-list__block {
                animation-delay: 0.2s;
            }
        }

        &--plan {
            top: 71%;
            left: 4%;

            .line-list__icon {
                @media all and (min-width: 1025px) {
                    transition: transform 0.3s cubic-bezier(.39,-.35,.21,1.7) 0.25s;
                }
            }

            .line-list__block {
                animation-delay: 0.45s;
            }
        }

        &--create {
            top: 14%;
            left: 65%;

            @media all and (min-width: 1025px) and (max-width: 1300px) {
                left: 63%;
            }

            .line-list__icon {
                @media all and (min-width: 1025px) {
                    transition: transform 0.3s cubic-bezier(.39,-.35,.21,1.7) 2.3s;
                }
            }

            .line-list__block {
                animation-delay: 2s;
            }
        }

        &--learn {
            top: 43%;
            left: 58%;

            @media all and (min-width: 1025px) and (max-width: 1300px) {
                left: 56%;
            }

            .line-list__icon {
                @media all and (min-width: 1025px) {
                    transition: transform 0.3s cubic-bezier(.39,-.35,.21,1.7) 2.4s;
                }
            }

            .line-list__block {
                animation-delay: 2.2s;
            }
        }

        &--improve {
            top: 71%;
            left: 51%;

            @media all and (min-width: 1025px) and (max-width: 1300px) {
                left: 49%;
            }

            .line-list__icon {
                @media all and (min-width: 1025px) {
                    transition: transform 0.3s cubic-bezier(.39,-.35,.21,1.7) 2.8s;
                }
            }

            .line-list__block {
                animation-delay: 2.4s;
            }
        }
    }

    &__icon {
        position: relative;
        display: block;
        flex-shrink: 0;
        margin: 1rem 1rem 0 0;
        width: 100px;
        height: 100px;
        border-radius: 100px;
        background-color: #fff;
        text-align: center;
        border: 2px solid $colorRed;
        transform: scale(0);

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:before,
        &:after,
        &-round-inner {
            position: absolute;
            content: '';
            height: 100%;
            width: 100%;
            top: 0%;
            left: 0%;
            border-radius: 50%;
            box-shadow: 0 0 2px #fff;
            animation: glow-grow 4s ease-out infinite;
        }

        &:after {
            animation-delay: .66s;
        }

        &-round-inner {
            animation-delay: 1.33s;
        }
    }

    &__headline {
        margin: {
            top: 20px;
            left: -20px;
        }
        color: #fff;
        transition: color 0.5s, margin-top 0.3s ease-in, margin-left 0.5s, transform 0.3s;

        .is-active & {
            margin-top: 0;
            margin-left: 0;
            color: inherit;
        }
    }

    &__text {
        opacity: 0;
        transition: opacity 0.3s;

        .is-active & {
            opacity: 1;
        }
    }

    &__block {
        padding: 2rem 2.5rem;
        //background-color: #fff;
        color: $colorMain;
        max-width: 400px;
        opacity: 0;

        transition: opacity 0.4s, background-color 0.6s, height 0.9s;

        @media all and (max-width: 1024px) {
            height: 0;
        }

        .is-active & {
            background-color: #fff;

            @media all and (max-width: 1024px) {
                height: auto;
            }
        }

        @media all and (min-width: 1025px) and (max-width: 1300px) {
            padding: 1rem 1.5rem;
            max-width: 320px;
        }

        @media all and (max-width: 1024px) {
            max-width: none;
            width: 100%;
        }

        p {
            font: {
                size: rem(14px);
                weight: 300;
                line-height: rem(20px);
            }

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

    &__icon,
    &__block {
        @media all and (max-width: 1024px) {
            animation-duration: 1s !important;
            animation-delay: 0s !important;
        }
    }

}

@keyframes dash {
    from {
        stroke-dashoffset: 5000;
    }
    to {
       stroke-dashoffset: 0;
    }
}
