/**
*
* Content (text stylisation connected to CMS WYSIWYG editor)
*
**/
.content {
    p,
    li,
    td {
        // font-size: rem(18.1px);
        @include dynamicFontSize(16, 16.5, 480, 1600);
        line-height: 1.8rem;
        font-weight: 300;
        letter-spacing: 0;

    }

    .content__small-text {
        @include dynamicFontSize(13, 14, 480, 1600);
        line-height: 1.45;
    }

    .content__large-text,
    .content__large-text p,
    .content__large-text li {
        @include dynamicFontSize(18, 22, 480, 1600);
        line-height: 1.35;
    }

    a:not([class]) {
        color: $colorRed;

        &:hover {
            color: $colorMain;
        }
    }

    p:not(:last-child) {
        margin: 0;
        padding-bottom: 1.5em;
    }

    ul:not(:last-child),
    ol:not(:last-child) {
        padding-bottom: 1em;
    }

    p {
        margin-top: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    p + ul,
    p + ol {
        //margin-top: -.7em !important;
    }

    /* uls, ols */
    ul {
        @extend .list-ul;
    }

    ol {
        @extend .list-ol;
    }

    blockquote {
        @extend .blockquote;
    }

    .social-media {
        @extend .social-media;
    }

    .social-media--black {
        @extend .social-media--black;
    }

    .list-with-arrows {
        @extend .list-with-arrows;
    }
}
