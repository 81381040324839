
$mainContainerMaxWidth: 1360;
$narrowContainerMaxWidth: 830;
$smallContainerMaxWidth: 1150;
$mediumContainerMaxWidth: 1270;
$superMediumContainerMaxWidth: 1550;
$largeContainerMaxWidth: 1600;
$smallerContainerMaxWidth: 1000;

.container {
    position: relative;
    margin-right: auto;
    margin-left: auto;

    width: 92%;
    max-width: ($mainContainerMaxWidth)+px;

    &-narrow {
        @extend .container;
        max-width: ($narrowContainerMaxWidth)+px;
    }

    &-medium {
        @extend .container;
        max-width: ($mediumContainerMaxWidth)+px;
    }

    &-super-medium {
        @extend .container;
        max-width: ($superMediumContainerMaxWidth)+px;
    }

    &-small {
        @extend .container;
        max-width: ($smallContainerMaxWidth)+px;
    }

    &-smaller {
        @extend .container;
        max-width: ($smallerContainerMaxWidth)+px;
    }

    &-large {
        @extend .container;
        max-width: ($largeContainerMaxWidth)+px;
    }

    &-fluid {
        padding-left: 0;
        padding-right: 0;
        overflow-x: hidden;
    }

    &-full-pad {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        padding-left: 50px;
        padding-right: 50px;
    }

    &--padding {
        padding: 80px;

        @media all and (max-width: 768px) {
            padding: 30px;
        }
    }
}
