/*==========  GLOBAL STYLES  ==========*/

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body,
button {
    //-moz-osx-font-smoothing: antialiased;
    //-webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

html {
    background-color: #fff;
    overflow-y: auto;
}

body {
    font-family: 'Montserrat', sans-serif;
    //letter-spacing: 0.02em;
    font-size: 100%;
    line-height: 1.5;
    font-weight: 300;
    color: $colorDarkGrey;
    overflow-x: hidden;
}

.page-holder {
    width: 100%;
    transition: transform .5s;
    transition-timing-function: ease-in-out;

    .nav-open & {
        @media all and (min-width: 1025px) {
            transform: translateX(-720px);
            transition: transform .5s .25s;
        }
    }
}

ul, li {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;

    &:not([class]) {
        color: inherit;
        transition: color 0.3s;

        &:hover {
            color: $colorRed;
        }
    }
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

video {
    background: #fff url(../images/ajax-loader.gif) center center no-repeat;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

::selection {
    color: white;
    background: rgba(226, 23, 55, 0.8);
}
