/**
*
* Blockquote
*
**/

.blockquote {
    position: relative;
    margin: 1em 0;
    padding: 1em 3em;
    text-align: center;

    @media all and (min-width: 1024px) {
        padding: 2em 10em;
    }

    &:before,
    &:after {
        position: absolute;

        font-size: 6em;
        font-weight: 500;
        line-height: 1;

        @media all and (min-width: 1024px) {
            font-size: 20em;
        }
    }

    &:before {
        content: '\201C';
        top: -2%;
        left: 0;

        @media all and (min-width: 1024px) {
            top: -20%;
        }
    }

    &:after {
        content: '\201D';
        bottom: 5%;
        right: 0;

        @media all and (min-width: 1024px) {
            bottom: -45%;
        }
    }

    &--small {
        @media all and (min-width: 1024px) {
            padding: 1em 2em;

            &:after,
            &:before {
                font-size: 4em;
            }

            &:before {
                top: -3%;
            }

            &:after {
                bottom: 25%;
            }
        }

        .blockquote__text {
            font-weight: 400;
            @include dynamicFontSize(12, 21, 480, 1600);
        }
    }

    &__text {
        @include dynamicFontSize(12, 19, 480, 1600);
        font-weight: 500;
    }

    &__footer {
        margin-top: 3.5em;
    }

    &__author {
        margin-bottom: 0.1em;
    }

    &__position {
        @include dynamicFontSize(12, 19, 480, 1600);
        font-weight: 400;
    }
}