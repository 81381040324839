//==========  VARIABLES  ==========

// Colors
.color-main {color: $colorMain;}
.color-red {color: $colorRed;}
.color-grey {color: $colorGray;}

// Breakepoints
$breakpointMobile: 768px;
$breakpointTablet: 1024px;
$breakpointHDready: 1366px;
$breakpointFullHD: 1920px;

// Animations
$anim_duration1: 600ms;

//==========  MIXINS  ==========

// Inline-block
@mixin inline-block() {
    display: inline-block;
    vertical-align: middle;
}

// Clearfix
@mixin clearfix() {
    &:before, &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both
    }
}

// Hide-text
@mixin hidetext() {
    text-indent: 200%;
    white-space: nowrap;
    overflow: hidden;
}

$base-font-size: 16px;
@function em($px, $base: $base-font-size) {
    @return ($px / $base) * 1em;
}
@function rem($px, $base: $base-font-size) {
    @return ($px / $base) * 1rem;
}

@mixin mediaquery($query){

    /////////////////////////////////////
    // NOTE:
    // To prevent overlapping closed ranges
    // all 'min' values should be 'breakepoint + 1' (odd)
    // and all 'max' values should be at 'breakepoint' (even).
    // Examples of correct ranges: 1025-1920, 1025-1366, 769-1366 (where 'breakepoints' are 768, 1024, 1366, 1920)
    // Incorrect ranges: 1024 - 1920, 1024-1919, 1025-1919

    ////////////////////// Closed ranges

    // 0 - 767 (exception from the rule above)
	@if $query == mobile {
		@media only screen and(max-width: #{$breakpointMobile - 1px}) { @content; }
	}

	// 768 - 1024
	@else if $query == tablet {
		@media only screen and(min-width: #{$breakpointMobile}) and(max-width: #{$breakpointTablet}) { @content; }
	}

    // 1025 - 1366
    @else if $query == hdready {
        @media only screen and(min-width: #{$breakpointTablet + 1px}) and(max-width: #{$breakpointHDready}) { @content; }
	}

    // 1367 - 1920
    @else if $query == fullhd {
        @media only screen and(min-width: #{$breakpointHDready + 1px}) and(max-width: #{$breakpointFullHD}) { @content; }
	}


    ////////////////////// Open ranges

    // 768 - *
	@else if $query == gt_mobile {
		@media only screen and(min-width: #{$breakpointMobile}) { @content; }
	}

    // 1025 - *
	@else if $query == gt_tablet {
		@media only screen and(min-width: #{$breakpointTablet + 1px}) { @content; }
	}

    // 1367 - *
	@else if $query == gt_hdready {
		@media only screen and(min-width: #{$breakpointHDready + 1px}) { @content; }
	}

    // 1921 - *
    @else if $query == cinema {
        @media only screen and(min-width: #{$breakpointFullHD + 1px}) { @content; }
    }

    ////////////////////// Other

    // Retina
    @else if $query == retina {
		@media only screen and (-moz-min-device-pixel-ratio: 1.5),
		       only screen and (-o-min-device-pixel-ratio: 3/2),
		       only screen and (-webkit-min-device-pixel-ratio: 1.5),
		       only screen and (min-device-pixel-ratio: 1.5)
		       { @content; }
	}

}

//
// example of use:
// @include dynamicFontSize(16, 28, 480, 1600);
//

@mixin dynamicFontSize($minFontSize, $maxFontSize, $minWidth, $maxWidth) {
    // font-size: calc(ZZ + ((1vw - XX) * YY));
    $font_Size_Difference: $maxFontSize - $minFontSize;
    $viewport_Width_Difference: $maxWidth - $minWidth;
    $YY: 100 * $font_Size_Difference/$viewport_Width_Difference;
    $XX: #{$minWidth / 100};

    font-size: #{$minFontSize}px;

    @media screen and (min-width: #{$minWidth}px) {
        font-size: calc(#{$minFontSize}px + ( (1vw - #{$XX}px) * #{$YY}) );
    }

    @media screen and (min-width: #{$maxWidth}px) {
        font-size: #{$maxFontSize}px;
    }
}

@mixin linkHoverLine($lineHeight, $lineColor) {
    position: relative;
    display: inline-block;

    @media all and (min-width: 769px) {
        &:after {
            content: ' ';
            position: absolute;
            bottom: 13%;
            left: 0;
            z-index: -1;
            display: block;
            height: $lineHeight;
            width: 0;
            background-color: $lineColor;
            transition: width .5s;
        }

        &:hover {
            &:after {
                width: 100%;
            }
        }
    }
}

// Clearfix (recommended to use)
.clear,
%clearfix {
    &::before,
    &::after {
        content: '';
        display: table;
    }

    &::after {
        clear: both;
    }
}

// Inline block with vertical middle alignment
%verticalMiddleInlineBlock {
    display: inline-block;
    vertical-align: middle;
}

%verticalAlignment {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

// skewed edges
@mixin angle-edge($pos-top:null, $angle-top:null, $pos-btm:null, $angle-btm:null){
  width: 100%;

  &::before,
  &::after{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: -1;
    transition: ease all .5s;
  }

  &:before {
    background: linear-gradient(to bottom, rgba(215, 215, 215, 0.22) 0%, rgba(255, 255, 255, 0) 100%);
  }
 
  @if $pos-top{
    &::before{
      @if $pos-top == 'topleft'{
        top: -50%;
        transform-origin: left top;
        transform: skewY($angle-top);

        @media all and (max-width: 1670px) {
            top: -6%;
        }
      }
      @if $pos-top == 'topright' {
        top: 0;
        transform-origin: left top;
        transform: skewY(-$angle-top);
      } 
    }
  }
 
  @if $pos-btm{
    &::after{
      @if $pos-btm == 'bottomleft' {
        bottom: 0;
        transform-origin: right bottom;
        transform: skewY(-$angle-btm);
 
      } 
 
      @if $pos-btm == 'bottomright' {
        bottom: 0;
        transform-origin: left bottom;
        transform: skewY($angle-btm);
      } 
    }
  }
}