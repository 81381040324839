.heart2-container{
    z-index: 1;
    position: absolute;
    right:18%;
    top:50%;
    transform: translate(50%,-50%) rotate(-45deg);
    overflow: visible;
    transition:  top 1.5s, right 1.5s, transform 1.5s;
    transition-timing-function: ease-in-out;

    @media all and (min-width: 769px) and (max-width: 1023px) {
        right: 13%;
        transform: translate(50%,-50%) rotate(-45deg) scale(0.5);
    }

    @media all and (max-width: 768px) {
        top: 45%;
        right: 50%;
        z-index: 2;

        opacity: 0.25;
    }
}

// .nav-open .heart2-heart{
//     transform: translate(-225px, -225px);
//     transition: fill 2s, stroke 2s, stroke-width 2s, transform .5s .25s;
// // transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
// }

// .nav-open.team .heart2-heart{
//     transform: translate(-80px, -80px);
// }

.heart2-heart{
    transform:translateX(0);
}

.heart2-piece{
    width: 150px;
    height: 150px;
    border: 5px solid #ffffff;
    border-radius:300px;
    position: absolute;
    top:0;
    right:0;
    box-sizing: border-box;
    opacity: 0;

    @media all and (max-width: 768px) {
        border: 5px solid $colorRed;
    }
}

#circleL-svg{
    overflow:visible;
    padding: 2.5px;
    animation: circleLsvg 1s ease-in-out 3s;
    animation-fill-mode: forwards;
}

.heart2-svg-piece{
    stroke: #ffffff;
    fill: rgba(255, 0, 0, 0);
    stroke-width: 5px;
    position: absolute;
    top:0;
    right:0;
    stroke-dasharray: 471px, 471px;
    stroke-dashoffset: 471px;
    animation: circleL 3s ease-in-out 2s;
    animation-fill-mode: forwards;
    overflow: visible;

    @media all and (max-width: 768px) {
        stroke: $colorRed;
    }
}


.heart2-circleR{
    animation: circleR 2s ease-in-out 3s;
    animation-fill-mode: forwards;
}

.heart2-square{
    animation: square 2s ease-in-out 3s;
    animation-fill-mode: forwards;
}

#heart-svg{
    position: absolute;
    transform: translateY(-33.333333%);
    z-index: 1;
    padding:7.5px;
    overflow: visible;
    path {
        animation: heart 2s ease-in-out 4s;
        animation-fill-mode:forwards;
        stroke-dasharray: 308.522px, 308.522px;
        stroke-dashoffset: 308.522px;
        stroke-linecap: round;
        stroke: #fff;
        stroke-width: 5px;
    //    fill: rgba(226, 24, 56, 0);

        @media all and (max-width: 768px) {
            stroke: $colorRed;
        }
    }
}


@keyframes circleR{
    from{
    opacity: 1;
    transform: translateY(0);
    }
    50%{
    opacity: 1;
    transform: translateX(50%);
    }
    to{
    opacity: 0;
    transform: translateX(50%);
    }
}

@keyframes circleL {
    from{
    stroke-dashoffset: 471px;
    }
    33%{
    stroke-dashoffset: 0;
    fill: rgba(255, 0, 0, 0);
    }
    66%{
    stroke-dashoffset: 0;
    opacity:1;
    }
    to{
    stroke-dashoffset: 0;
    opacity:0;
    }
}

@keyframes circleLsvg{
    from{
    transform: translateY(0);
    }
    to{
    transform: translateY(-50%);
    }
}

@keyframes square{
    from{
    opacity:1;
    border-radius: 50%;
    }
    50%{
    opacity: 1;
    border-radius: 0;
    }
    to{
    opacity: 0;
    border-radius: 0;
    }
}

@keyframes heart{
    from{
    opacity: 1;
    stroke-dashoffset: 308.522px;
    fill-opacity: 0;
    }
    50%{
    fill-opacity: 0;
    stroke-dashoffset: 0;
    opacity:1;
    }
    to{
    fill-opacity: 1;
    stroke-dashoffset: 0;
    opacity:1;
//   fill: #e21838;
    }
}



/* ------------------------------------ */

.heart2-inner {
    box-sizing: content-box;
}

.heart2-heart{
    fill: #fff;
    opacity:0;
    transition: fill 2s, stroke 2s, stroke-width 2s, transform ease-in-out .5s;
  //  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;

    @media all and (max-width: 768px) {
        fill: $colorRed;
    }
  }

  #load-box.team {
    right: 47%;
    top: 141vh;
    transform: translate(50%, -50%) rotate(-45deg) scale(3);
    #circleL-svg{
      opacity:0;
    }
    .heart2-circleL, .heart2-circleR, .heart2-square{
      position: absolute;
    }
    .heart2-heart {
      fill: rgba(255, 136, 22, 0);
      stroke: #ff8816;
      stroke-width: 2px;
    }
  }

  .hide-shape {
    top: -75vh!important;
  }
