.post {
    padding-top: 120px;

    @media all and (min-width: 992px) {
        padding-top: 150px;
    }

    &__lead {
        margin: 0.5em 0 2em 0;

        @media all and (max-width: 767px) {
            margin-top: 1.5em;
        }

        p:first-child:first-letter {
            float: left;

            font-size: 4em;
            line-height: 0.6;
            font-weight: 500;
            padding-right: 10px;
            padding-top: 0.17em;
        }
    }

    &__media {
        margin: {
            top: 2em;
            bottom: 2em;
        }

        @media all and (min-width: 1024px) {
            margin: {
                left: -1.5em;
                right: -1.5em;
            }
        }

        img {
            width: 100%;
        }

        iframe {
            width: 100%;
            height: 450px;
        }

        figcaption {
            padding: 1em 2em;
            background-color: #ededed;

            font-size: 0.8em;
            color: #666;
        }
    }

    &__footer {
        padding-top: 3em;
        margin-top: 3em;

        border-top: 1px solid $colorLightGrey;
    }

    &__comments {
        margin-top: 4em;
    }
}