
.pull {
    &-up {
        @media all and (min-width: 1024px) {
            margin-top: -100px;
        }
    }

    &-down {
        margin-bottom: -100px;
    }
}
