/**
*
* Top navigation
*
**/

.l-navbar {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 82px;

    @media all and (min-width: 1024px) {
        height: 98px;
    }

    transition: transform 1s, background 1s;

    &--white {
        background-color: #fff;
        border-bottom: 1px solid #d2d2d2;

        .l-navbar__logo {
            padding: 1rem 1rem 1rem 2rem;

            svg {
                width: 118px;
                fill: #000;
            }
        }
    }

    &--hide {
        transform: translateY(-82px);

        @media all and (min-width: 1024px) {
            transform: translateY(-98px);
        }
    }

    &__logo {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 111;

        padding: 1.5rem 2rem;

        transition: padding 1s, width 1s;

        @media all and (min-width: 1024px) {
            padding: 2rem;
        }

        svg {
            width: 120px;
            height: 50px;

            fill: #1a1a1a;
            transition: padding 1s, width 1s, fill 0.5s;

            @media all and (min-width: 1024px) {
                width: 148px;
                height: 68px;
            }

            @media all and (min-width: 200px) and (max-width: 567px) {
                .nav-open & {
                    fill: #fff;
                    transition: fill .25s;
                }
            }
        }

        &--white {
            svg {
                fill: #fff;
            }
        }
    }

    &__container {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10;

        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100px;
        height: 0;

        background-color: #1a1a1a;
        overflow: hidden;

        color: #fff;

        @media all and (max-width: 567px) {
            opacity: 0;
            transition: opacity 0.3s;
        }

        @media all and (min-width: 568px) {
            transition: width .5s, height .25s .5s, background .25s .5s, z-index 1s .75s;
            transition-timing-function: ease-in-out, ease-in-out;
        }

        ::selection {
            background: #1a1a1a;
        }

        &.is-scrollable {
            overflow-y: auto;
        }

        .nav-open & {
            height: 100%;
            width: 720px;

            @media all and (max-width: 567px) {
                opacity: 1;
            }

            @media all and (min-width: 568px) {
                transition: width .5s .25s, height .25s, background .25s, z-index 0s;
            }

            @media all and (min-width: 568px) and (max-width: 1024px) {
                width: 470px;
            }

            @media all and (min-width: 200px) and (max-width: 567px) {
                width: 100%;
            }
        }
    }

    &__container-top {
        @media all and (max-width: 567px) {
            width: 100%;
            height: inherit;
            position: relative;
            left: 100%;
            z-index: 100;
            transition: left 0.001s;
            background-color: #1a1a1a;

            .nav-open & {
                left: 0;
                height: inherit;
                transition: left 0.001s;
            }
        }
    }

    &__spacer {
        display: table;
        width: 10px;
    }

    &__submenu {
        max-height: 0;
        overflow: hidden;

        margin: {
            top: 0.1em;
            left: 2em;
            bottom: 0.1em;
        }

        transition: max-height 0.5s;

        &-item {
            margin-top: 0.2em;
        }

        &-link {
            @include linkHoverLine(20%, $colorRed);
            padding: 0.2em 0.2em 0 0.2em;
            margin-left: 0.6em;

            font-size: rem(20px);
            color: #f1f1f1;

            opacity: 0;
            transform: scale(0.7);
            transition: opacity 0.5s, margin-left 0.4s, transform 0.5s;
        }
    }

    &__menu {
        margin: 5rem 0 0 5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media all and (max-width: 567px) {

            margin: {
                top: 8rem;
                left: auto;
                right: auto;
            }
        }

        &-item {
            margin: 1.3em 0;
        }

        &-link,
        &-headline {
            // display: block;

            padding: 0.2em 1em 0.1em 0.2em;
            font: {
                size: rem(43px);
                weight: bold;
            };
            line-height: 1;
            color: #fff;
            transition: color 0.1s;

            @media all and (max-width: 567px) {
                font-size: rem(32px);
            }

            &--sm {
                margin-left: 2rem;
                padding: 1rem 1rem 0.5rem 0.6rem;

                font: {
                    size: rem(32px);
                };
            }
        }

        &-link {
            @include linkHoverLine(28%, $colorRed);
            position: relative;

            &:hover {
                &:after {
                    width: 100%;
                }
            }

            &.is-active ~ .l-navbar__submenu {
                max-height: 200px;
            }

            &.is-active .l-navbar__menu-more {
                transform: rotate(90deg);
            }

            &.is-active ~ .l-navbar__submenu .l-navbar__submenu-link {
                opacity: 1;
                margin-left: 0;
                transform: scale(1);
            }
        }

        &-headline {
            color: #ededed;
        }

        &-link-right-item {
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -12.5px;

            width: 25px;
            height: 25px;
        }

        &-more {
            display: block;
            position: relative;
            width: 25px;
            height: 25px;
            transition: transform 0.2s;

            &:before,
            &:after {
                content: '';
                position: absolute;
                background-color: #f1f1f1;
                border-radius: 3px;

                transition: opacity 0.3s;
            }

            &:before {
                top: 50%;
                width: 100%;
                height: 5px;

                margin-top: -2.5px;

                .is-active & {
                    opacity: 0;
                }
            }

            &:after {
                width: 5px;
                height: 100%;
                left: 50%;

                margin-left: -2.5px;
            }
        }
    }


    &__footer {
        width: 350px;
        margin: {
            top: 2rem;
            bottom: 1rem;
        }

        &-item {
            display: block;
            margin: 0 0 1rem 1rem;
        }

        &-link {
            display: block;
            color: #fff;
            transition: opacity 0.5s;
            font: {
                size: rem(16px);
                weight: 400;
            }
            transition: color 0.2s;

            &:hover {
                color: $colorRed;
            }

            svg {
                fill: currentColor;
            }
        }
    }

    &__small-text {
        font: {
            size: rem(16px);
            style: normal;
        }
        color: #999;
    }
}
