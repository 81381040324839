

.icon {

    &-link {
        display: block;
        padding: 0.5rem;

        color: inherit;
        transition: color 0.3s;

        svg {
            transition: fill 0.3s;
        }
    }
}
