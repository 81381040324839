.tile-button {
    display: block;
    position: relative;
    padding: 3em 1em;

    text-align: center;
    color: $colorMain;

    background-color: #fff;
    box-shadow: 0px 0px 48px 0px rgba(0,0,0,0.25);
    transition: all 0.3s ease;

    &:after {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 20px;
        content: '';
        width: 20px;
        height: 20px;

        background: url(../images/icons/down-arrow.svg) center 80% no-repeat;
        background-size: 20px auto;
        transition: bottom 0.2s ease;
    }

    &:hover {
        background-color: #ededed;

        &:after {
            bottom: 15px;
        }
    }
}