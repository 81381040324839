
.author-card {
    display: flex;
    margin: 1.5em 0;

    color: inherit;
    transition: color 0.2s;

    &:last-child {
        margin-bottom: 0;
    }

    &__postedby {
        display: block;
        margin-bottom: 0.5rem;
        font: {
            size: rem(16px);
            weight: 300;
        };
        text-transform: uppercase;
        letter-spacing: rem(2.5px);
    }

    &__avatar {
        margin: 0 rem(20px) 0 0;
        flex: 0 0 75px;

        &--small {
            flex: 0 0 65px;
        }
    }

    p {
        margin: 0;
        
        font-size: 0.9em;
        line-height: 1.5;
        color: $colorMediumGray;
    }

    &:hover {
        color: $colorRed;
    }
}
