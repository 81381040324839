/**
*
* Top header
*
**/

.l-header {
    position: relative;
    overflow: hidden;

    &--fullscreen {
        @media all and (max-width: 450px) and (orientation: portrait) {
            min-height: 660px;
        }

        @media all and (min-width: 451px) and (max-width: 767px) and (orientation: portrait) {
            min-height: 600px;
        }

        @media all and (min-width: 768px) {
            height: 100vh;
        }

        @media all and (orientation: portrait) and (max-width: 1279px) {
            height: auto;
        }


        @media all and (orientation: landscape) and (max-width: 768px) {
            max-height: 700px;
        }

        @media all and (orientation: landscape) and (min-width: 769px) and (max-width: 1023px) {
            min-height: 800px;
        }

        @media all and (orientation: landscape) and (min-width: 1024px) {
            min-height: 700px;
        }


        @media all and (max-width: 1279px) {
            padding-bottom: 70px;
        }

        @media all and (max-width: 768px) {
            padding-top: 110px;
        }

        @media all and (min-width: 769px) and (max-width: 1279px) {
            padding-top: 150px;
        }

        .skew-overlay {
            min-height: 600px;

            @media all and (min-width: 1280px) and (max-width: 1700px) {
                left: -35vw;
            }

            @media all and (min-width: 1024px) and (max-width: 1279px) {
                left: -35vw;
            }

            @media all and (min-width: 769px) and (max-width: 1023px) {
                left: -30vw;
            }

            @media all and (max-width: 768px) {
                left: 0;
                transform: none;
            }

            &--triple {
                @media all and (max-width: 991px) {
                    left: 0;
                    transform: skewX(15deg);
                    z-index: 1;
                }
            }
        }
    }

    &--short {
        background-repeat: no-repeat;
        background-position: top right;

        @media all and (max-width: 767px) {
            padding-left: 5%;

            .l-header__bg-image {
                opacity: 0.2;
            }
        }

        @media all and (max-width: 1023px) {
            padding: {
                top: 140px;
                bottom: 110px;
            }
        }

        @media all and (min-width: 1024px) {
            height: 680px;
        }
    }

    &--plain {
        @media all and (max-width: 1023px) {
            padding: {
                top: 110px;
                bottom: 30px;
            }
        }

        @media all and (min-width: 1024px) {
            padding: {
                top: 150px;
                bottom: 100px;
            }
        }
    }

    &--red {
        background-color: $colorRed;

        @media all and (max-width: 768px) {
            background-color: transparent;
        }
    }

    &--anim {
        .l-header__content-text,
        .l-header__category-headline,
        .l-header__primary-headline {
            opacity: 1;
            transform: translateX(0);
        }
    }

    &__content-wrapper {
        @media all and (min-width: 1280px) {
            left: -35vw;
            width: 100vw;
            z-index: 3;

            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &--half {
            @media all and (min-width: 1280px) {
                left: 27vw;
            }

            .l-header__content {
                width: 22%;
                float: none;
                transform: none;
            }
        }
    }

    &__content-middle {
        @media all and (min-width: 992px) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
        }
    }

    &__video,
    &__bg-image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        z-index: 1;

        overflow: hidden;
    }

    &__video {
        left: 0;
        right: 0;

        video {
            min-width: 100%;
            min-height: 100%;
        }
    }

    &__bg-image {
        //background-color: #222;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        &--left {
            left: 0;
        }

        &--right {
            left: auto;
            right: 0;

            @media all and (max-width: 767px) {
                background-position: top right;
            }
        }
    }

    &__full-height-img {
        position: absolute;
        top: -100px;
        left: 0;
        z-index: 1;
        width: 100%;
        height: calc(100% + 100px);
        max-height: calc(100vh + 100px);
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__content {
        position: relative;
        z-index: 2;

        @media all and (max-width: 768px) {
            width: 80%;
            margin: {
                left: 10%;
                right: 10%;
            }
        }

        @media all and (min-width: 769px) and (max-width: 1279px) {
            width: 40%;
            margin-left: 10%;
        }

        @media all and (min-width: 1280px) {
            width: 40%;
            max-width: 750px;
            float: right;
            transform: translateX(-30%);
        }

        @media all and (min-width: 1280px) and (max-width: 1800px) {
            width: 53%;
            transform: none;
        }

        &-text {
            @media all and (min-width: 1280px) and (max-width: 1800px) {
                .l-header--fullscreen & {
                    margin-right: 15%;
                }
            }
        }

        &-static {
            position: relative;
            z-index: 2;
            padding: 110px 10% 0 10%;

            @media all and (min-width: 1280px) {
                padding-top: 150px;
            }

            &-media {
                width: 100%;
            }
        }
    }

    &__category-headline,
    &__content-text {
        transform: translateX(40px);
        opacity: 0;
        transition: transform 1s 1s, opacity 1s 1s;

        .icon-link {
            &:hover {
                color: $colorRed;

                svg {
                    fill: $colorRed;
                }
            }
        }
    }

    &__primary-headline {
        //max-width: 700px;

        transform: translateX(10vw);
        opacity: 0;
        transition: transform 1s, opacity 1s;

        margin: rem(5px) 0 rem(35px) 0;

        @media all and (min-width: 1280px) and (max-width: 1800px) {
            .l-header--fullscreen & {
                margin-right: 8%;
            }
        }

        &--spacing {
            margin: {
                top: 1em;
                bottom: 1em;
            }
        }
    }

    &__arrow-down {
        position: absolute;
        bottom: 30px;
        left: 50%;
        z-index: 2;

        transform: translateX(-50%);
    }

    &__grid-equal-height {
        z-index: 2;
    }

    &__grid-equal-height,
    &__grid-equal-height .row,
    &__grid-equal-height [class*="col-"] {
        @media all and (min-width: 1024px) {
            height: inherit;
        }
    }

    &__middle-aligned {
        @media all and (min-width: 1024px) {
            margin-top: 50px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__left-intro {
        padding-right: 7em;

        @media all and (max-width: 767px) {
            margin-bottom: 3em;
            padding-right: 2em;
        }

        &--wide {
            @media all and (max-width: 1699px) {
                padding-right: 2em;
            }
        }
    }

    &__post {
        @media all and (max-width: 767px) {
            margin-right: 7%;
            
            .btn--white.btn--grow:not([class*=color-]) {
                color: #111;
            }
        }

        a:not(.btn) {
            color: inherit;
        }

        @media all and (min-width: 768px) {
            padding-left: 7em;
            color: #fff;
        }

        &-small-headline {
            opacity: 0.5;
        }

        &-text:not(:last-child) {
            padding-top: 1.5em;
        }
    }

    &__overflow-visible {
        overflow: visible;
        margin-bottom: 5em;
    }
}
