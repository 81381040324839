.tile {
    overflow: hidden;
    position: relative;
    width: 25%;
    height: 380px;
    padding-bottom: 100px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    color: #fff;

    @media screen and (min-width: 650px) and (max-width: 1199px) {
        width: 50%;
    }

    @media screen and (min-width: 200px) and (max-width: 649px) {
        width: 100%;
        height: auto;
    }

    @media all and (min-width: 1200px) and (max-width: 1500px) {
        height: 430px;
    }

    &__link {
        display: block;

        color: #fff;

        &:hover {
            color: inherit;

            .tile__inner {
                // margin-top: -10px;

                transform: translateY(-10px);
            }

            .tile__description {
                // margin-top: -2em;

                transform: translateY(-2em);
            }
        }
    }

    a:hover {
        color: #ededed;
    }

    &__inner {
        transition: all 0.2s ease;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        transition: transform 0.3s ease;
    }

    &__header {
        padding: 2em 2.5em 1.5em 2.5em;
        min-height: 2em;
    }

    &__headline {
        padding: 4em 2.5em 0 2.5em;
    }

    &__description {
        padding: 1.5em 2.5em 1.5em 2.5em;
        transition: margin-top 0.4s ease, transform 0.4s ease;

        p {
            font-size: 0.9em;
            letter-spacing: 0.03em;
        }

        &--special-text {
            p {
                font: {
                    family: 'Poppins', sans-serif;
                    weight: 200;
                }
                letter-spacing: 0.07em;
                @include dynamicFontSize(16, 16.5, 480, 1600);
            }
        }
    }

    &__bottom {
        position: absolute;
        z-index: 10;
        bottom: 2em;
        left: 2.5em;
        right: 2.5em;

        color: #fff;
    }

    &__small-headline {
        display: inline-block;
        margin-bottom: 0.5em;
        opacity: 0.5;
    }
}
