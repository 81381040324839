/**
*
* Headlines
*
**/

.headline {
    display: block;
    width: auto;
    margin: 0 0 0.25em 0;
    font-family: 'Poppins', sans-serif;

    &-1 {
        @extend .headline;
        // font-size: rem(64px);
        font-weight: 700;
        line-height: 1.1;

        @include dynamicFontSize(36, 64, 480, 1600);
    }

    &-2 {
        @extend .headline;
        // font-size: rem(70px);
        font-weight: 700;
        line-height: 1.1;

        @include dynamicFontSize(40, 70, 480, 1600);
    }

    &-3 {
        @extend .headline;
        margin-bottom: 0.5em;

        // font-size: rem(48px);
        font-weight: 700;
        line-height: 1.1;

        @include dynamicFontSize(27, 48, 480, 1600);
    }

    &-4 {
        @extend .headline;
        margin-bottom: 0.5em;

        @include dynamicFontSize(28, 36, 480, 1600);
        font-weight: 700;
        line-height: 1.1;

        /*.content & {
            margin-bottom: 1em;
        }*/
    }

    &-5 {
        @extend .headline;
        font-family: 'Montserrat', sans-serif;
        // font-size: rem(16px);
        @include dynamicFontSize(16, 17, 480, 1600);
        font-weight: 500;
        letter-spacing: rem(2px);
    }

    &-6 {
        @extend .headline;
        // font-size: rem(32px);
        font-weight: 500;
        line-height: 1.1;

        @include dynamicFontSize(24, 32, 480, 1600);
    }

    &-7 {
        @extend .headline;
        font-family: 'Montserrat', sans-serif;
        // font-size: rem(22px);
        font-weight: 400;
        letter-spacing: rem(6px);

        @include dynamicFontSize(16, 20, 480, 1600);
    }

    &-8 {
        @extend .headline;
        margin-bottom: 0.5em;

        font-size: rem(20px);
        font-weight: 700;
        line-height: 1.1;
    }

    &-9 {
        @extend .headline;
        margin-bottom: 0.8em;

        @include dynamicFontSize(25, 44, 480, 1600);
        font-weight: 700;
        line-height: 1.5;
        letter-spacing: 0.025em;
    }

    &-10 {
        @extend .headline;
        margin-bottom: 0.5em;

        // font-size: rem(28px);
        font-weight: 700;
        line-height: 1.1;

        @include dynamicFontSize(20, 28, 480, 1600);

        .content & {
            margin-bottom: 1em;
        }
    }

    &-11 {
        @extend .headline;
        margin-bottom: 0.3em;

        // font-size: rem(55px);
        font-weight: 700;
        line-height: 1.2;

        @include dynamicFontSize(32, 55, 480, 1600);
    }

    &-12 {
        @extend .headline;
        margin-bottom: 0.2em;

        font-size: rem(20px);
        letter-spacing: 0.04em;
        font-weight: 500;
        line-height: 1.15;
    }

    &-13 {
        @extend .headline;
        margin-bottom: 0.3em;

        // font-size: rem(55px);
        font-weight: 500;
        line-height: 1.2;

        @include dynamicFontSize(23, 27, 480, 1600);
    }

    &--lh-15 {
        line-height: 1.5;
    }

    &-border-through {
        position: relative;
        margin-bottom: 0;

        &__text {
            position: relative;
            z-index: 3;
            display: inline-block;
            padding-right: 2%;

            background-color: #fff;
        }

        &:after {
            content: '';
            @extend %verticalAlignment;
            left: 0;
            right: 0;
            height: 2px;
        }

        $borderColors: (
            'red': $colorRed,
            'lightgrey': $colorLightGrey
        );

        @each $colorName, $color in $borderColors {
            &--#{$colorName} {
                &:after {
                    background-color: $color;
                }
            }
        }
    }

    &-thin {
        font-weight: 300;
    }
}
