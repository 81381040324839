

// Colors

.color {
    @each $color, $color_code in $bgColors {
        &-#{$color} {
            color: $color_code;
        }
    }

    @each $color, $color_code in $csColors {
        &-#{$color} {
            color: $color_code;
        }
    }
}
