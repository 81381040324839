%cover {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.bg-cover {
    &__inner {
        position: relative;
        z-index: 20;
    }

    &:after {
        transition: opacity 0.2s ease;
    }

    @each $name, $color in $bgColors {
        &--#{$name} {
            &:after {
                @extend %cover;
                background-color: $color;
                opacity: 0.85;
            }

            &:not(.bg-cover--no-hover):hover {
                &:after {
                    opacity: 1;
                }
            }
        }
    }
}