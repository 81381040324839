@keyframes MoveUpDown {
    0% {
        bottom: 0;
    }
    50% {
        bottom: 20px;
    }
    100% {
        bottom: 0;
    }
}

.arrow-down {
    display: block;
    margin-bottom: 1.5em;
    width: 30px;
    animation: MoveUpDown 2s ease-in-out infinite;

    svg {
        fill: none;
        stroke: $colorRed;
        stroke-width: 4;
        stroke-linecap: round;
        stroke-linejoin: round;
        transition: all 0.2s ease;
    }

    &:hover {
        animation-play-state: paused;

        svg {
            transform: scale(1.3);
        }
    }
}