.section {
    position: relative;

    @media all and (max-width: 768px) {
        padding: 2em 0;
    }

    @media all and (min-width: 769px) and (max-width: 1279px) {
        padding: 3.5em 0;
    }

    @media all and (min-width: 1280px) {
        padding: 6em 0;
    }

    &--introduction {
        &__icon {
            display: inline-block;
            width: 150px;
            margin-bottom: 2em;
        }

        &__headline {
            max-width: 500px;
        }

        [class*="headline-"] {
            margin-bottom: 3rem;
        }

        &__para {
            max-width: 84%;
            margin: {
                left: auto;
                right: auto;
            }
        }
    }

    &--featured-events {
        position: relative;
        z-index: 1;
        margin-bottom: -140px;
    }

    &--bg-cover {
        background-size: cover;
    }

    &__main-headline {
    	text-align: center;
    	margin-bottom: 3em;
    }

    &__full-width {
        width: 100%;
    }

    &__video-player {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;


        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
